





















































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import 'moment-timer'
import 'bootstrap/dist/css/bootstrap.css'

// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvHeader6 from '$ui/dv/packages/my-dv-header6'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

@Component({
  components: {
    MyDvMenu,
    MyDvTitle,
    MyDvHeader6,
    MyDvBox,
  },
})
export default class extends Mixins() {
  curtime: string = ''

  cururi: string = '/v2/index'

  mounted() {
    console.log(this.$route)
    this.cururi = this.$route.path
    // @ts-ignore
    const timer2 = moment.duration(1, 'seconds').timer({ loop: true }, () => {
      this.curtime = moment().format('YYYY年MM月DD日 HH:mm:ss')
    })
  }

  jumpPage(uri: string) {
    this.cururi = uri
    this.$router.push(uri)
  }
}
