var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app2"}},[_c('div',{staticClass:"index_nav"},[_c('ul',{staticStyle:{"height":"1.563vw","margin-bottom":"0px"}},[_c('li',{class:[
          'l_left',
          'total_chose_fr',
          _vm.cururi.indexOf('/v2/index') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/index')}}},[_vm._v(" 实时监测 ")]),_c('li',{class:[
          'l_left',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/stadium') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/stadium')}}},[_vm._v(" 场馆运营 ")]),_c('li',{class:[
          'l_left',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/user') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/user')}}},[_vm._v(" 小羽服务 ")]),_c('li',{class:[
          'r_right',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/demo3') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/demo3')}}},[_vm._v(" 统计分析三 ")]),_c('li',{class:[
          'r_right',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/demo4') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/demo4')}}},[_vm._v(" 统计分析四 ")]),_c('li',{class:[
          'r_right',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/demo5') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/demo5')}}},[_vm._v(" 统计分析五 ")]),_c('li',{class:[
          'r_right',
          'total_chose_pl',
          _vm.cururi.indexOf('/v2/demo6') !== -1 ? 'nav_active' : '' ],on:{"click":function($event){return _vm.jumpPage('/v2/demo6')}}},[_vm._v(" 统计分析六 ")])]),_c('div',{staticClass:"clearfix"}),_c('div',{staticStyle:{"width":"100%","height":"1.563vw","color":"#ffffff","text-align":"right"}},[_c('div',{staticStyle:{"padding-right":"1.042vw"}},[_vm._v(_vm._s(_vm.curtime))])])]),_c('div',{staticStyle:{"width":"100%"}},[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }